<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 상단 -->
            <ContactHeader />

            <v-sheet class="d-flex justify-center align-center rounded-15" height="80">
                <!-- TEXT -->
                <v-text-field
                    height="50"
                    style="font-size:18px; max-width:260px;"
                    class="ml-2 rounded-15 font-weight-bold"
                    outlined
                    hide-details
                    dense
                    placeholder="검색"
                    persistent-placeholder
                    v-model="keyword"
                    append-icon="mdi-magnify"
                    @keyup.enter="submit()"
                >
                    <template v-slot:append>
                        <v-icon size="30" class="pt-1 mt-1px mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="ml-3 rounded-10 font-weight-bold"
                    style="font-size:18px;"
                    color="primary"
                    width="86"
                    height="50"
                    @click="submit()"
                >
                    검색
                </v-btn>
            </v-sheet>

            <!-- 목록 상단 -->
            <div class="px-4 py-3">
                <table>
                    <tr>
                        <td class="text-center" width="820px" style="font-size:16px; font-weight:bold;">문의</td>
                        <td class="text-center" width="200px" style="font-size:16px; font-weight:bold;">상태</td>
                    </tr>
                </table>
            </div>

            <!-- 본문 -->
            <v-sheet class="px-7 py-4 rounded-15">
                <!-- 목록 -->
                <v-data-table
                    :items="list"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount = $event"
                    hide-default-footer
                    no-data-text="문의내역이 존재하지 않습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr 
                            class="pa-0 ma-0" 
                            style="cursor:pointer;" 
                            @click="read(item)"
                        >
                            <td class="py-4">
                                <v-sheet class="d-flex" color="transparent">
                                    <v-sheet color="transparent">
                                        <v-sheet width="600" color="transparent" class="text-truncate mb-2">
                                            <v-sheet color="transparent">
                                                <font v-if="item.category" class="mr-2" style="font-size:19px; color:#1DA1F2;">{{item.category}}</font>
                                                <font :class="item.type=='공지'? 'font-weight-bold deep-orange--text':''" style="font-size:19px;">{{item.title}}</font>
                                            </v-sheet>
                                        </v-sheet>
                                        <font style="font-size:16px; color:#B3B3C2;">{{new Date(item.created).toLocaleDateString()}}</font>
                                    </v-sheet>
                                    <v-spacer></v-spacer>
                                    <v-sheet class="mx-1" color="transparent">
                                        <v-sheet
                                            class="d-flex align-center justify-center mt-1 rounded-10"
                                            :style="item.isAnswered? 'background:#F8468D':'background:#ADAFCA'"
                                            width="95"
                                            height="45"
                                        >
                                            <p v-if="item.isAnswered" class="ma-0 white--text" style="font-size:15px;">
                                                답변완료
                                            </p>
                                            <p v-if="!item.isAnswered" class="ma-0 white--text" style="font-size:18px;">
                                                접수
                                            </p>
                                        </v-sheet>
                                    </v-sheet>
                                </v-sheet>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <v-divider></v-divider>

                <!-- 버튼 & 페이지네이션 -->
                <v-sheet class="mt-3 mx-3 d-flex align-center">
                    <v-sheet
                        width="110"
                        height="40"
                    ></v-sheet>
                    <v-spacer></v-spacer>

                    <!-- 페이징 -->
                    <v-pagination
                        class="my-2 elevation-0"
                        color="blue"
                        v-model="page"
                        :length="pageCount"
                        :total-visible="7"
                    ></v-pagination>
                    <v-spacer></v-spacer>

                    <!-- 문의하기 -->
                    <v-btn
                        width="110"
                        height="40"
                        class="rounded-10 font-weight-bold"
                        style="font-size:18px; letter-spacing:-0.4px;"
                        color="primary"
                        dark
                        depressed
                        @click="write()"
                    >
                        문의하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 제목 -->
            <div
                class="d-flex justify-space-between align-center px-5 py-3 mobile"
            >
                <p
                    style="font-size:16px; font-weight:bold; cursor:pointer;"
                    class="mb-0"
                    @click="$router.push('/contact/list')"
                >
                    내 문의내역
                </p>

                <v-btn
                    width="94"
                    height="28"
                    style="font-size:12px;"
                    dark
                    color="#615DFA"
                    class="rounded-10"
                    depressed
                    @click="$router.push('/contact/write')"
                >
                    <v-icon small class="mr-1">mdi-pencil-outline</v-icon>
                    문의하기
                </v-btn>
            </div>
            
            <v-sheet class="d-flex justify-center align-center px-8 py-3">
                <!-- TEXT -->
                <v-text-field
                    height="40"
                    style="font-size:12px;"
                    class="ml-2 rounded-10 font-weight-bold"
                    outlined
                    hide-details
                    dense
                    placeholder="검색"
                    persistent-placeholder
                    v-model="keyword"
                    append-icon="mdi-magnify"
                    @keyup.enter="submit()"
                >
                    <template v-slot:append>
                        <v-icon size="18" class="pt-1 pb-2 mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    small
                    class="ml-3 rounded-10 font-weight-bold"
                    style="font-size:14px;"
                    color="primary"
                    width="40"
                    height="40"
                    @click="submit()"
                >
                    검색
                </v-btn>
            </v-sheet>

            <v-divider style="opacity:0.6;"></v-divider>

            <v-sheet>
                <!-- 목록 -->
                <v-data-table
                    :items="list"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount = $event"
                    hide-default-footer
                    no-data-text="문의내역이 존재하지 않습니다."
                >
                    <template v-slot:item="{ item }">
                        <!-- 일반글 -->
                        <tr
                            class="py-1 px-2 ma-0"
                            style="cursor:pointer; border-bottom:1px solid #eee; width:100%;" 
                            @click="read(item)"
                        >
                            <td
                                colspan="2"
                                class="px-3"
                                style="border-bottom:none; width:100vw;"
                            >
                                <!-- 카테고리 & 제목 -->
                                <v-sheet
                                    style="max-height:36px; line-height:18px;"
                                    class="custom-truncate-mobile mt-3px"
                                    color="transparent"
                                >
                                    
                                    <span>{{item.title}}</span>
                                </v-sheet>

                                <!-- 작성 정보 -->
                                <v-sheet
                                    class="mt-1 d-flex align-center text-truncate"
                                    color="transparent"
                                    width="100%"
                                >
                                    <!-- 카테고리 -->
                                    <span v-if="item.category" style="color:#1DA1F2; font-size:11px;" class="mr-1">{{item.category}}</span>

                                    <!-- 작성일 & 조회수 & 좋아요 -->
                                    <p class="ml-6px mb-0 grey--text" style="font-size:8px;">
                                        {{
                                            new Date().toLocaleDateString() == new Date(item.created).toLocaleDateString()?
                                            new Date(item.created).toLocaleTimeString() : new Date(item.created).toLocaleDateString().slice(5)
                                        }}
                                    </p>

                                    <v-spacer></v-spacer>

                                    <!-- 작성일 & 조회수 & 좋아요 -->
                                    <p v-if="item.answer_created" style="font-weight:medium; font-size:8px; color:#F8468D;" class="ma-0 mr-2">
                                        {{
                                            new Date().toLocaleDateString() == new Date(item.answer_created).toLocaleDateString()?
                                            new Date(item.answer_created).toLocaleTimeString() : new Date(item.answer_created).toLocaleDateString().slice(5)
                                        }}
                                    </p>

                                    <p v-if="item.isAnswered" class="ma-0" style="font-weight:medium; font-size:10px; color:#F8468D;">
                                        답변완료
                                    </p>
                                    <p v-if="!item.isAnswered" class="ma-0" style="font-weight:medium; font-size:10px; color:#424361;">
                                        접수완료
                                    </p>
                                </v-sheet>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <!-- 페이징 -->
                <v-pagination
                    class="my-2 elevation-0 d-flex justify-center"
                    color="blue"
                    v-model="page"
                    :length="pageCount"
                    :total-visible="7"
                ></v-pagination>
            </v-sheet>

            <!-- 메뉴 -->
            <v-btn
                width="120"
                height="40"
                class="px-0 mt-3 mb-16 rounded-10 mx-auto d-block"
                depressed
                color="white"
                v-ripple="false"
                style="font-size:12px; letter-spacing:-1px; min-width:0px; color:#424361;"
                @click="$router.push('/board/list?board_group=너정다&board_name=이용가이드')"
            >
                <v-icon small class="mr-1" color="#90909A">mdi-help-circle-outline</v-icon>
                이용가이드
            </v-btn>
        </div>
    </div>
</template>
<script>
import ContactHeader from "./components/Header"

export default {
    components: {
        ContactHeader
    },

    data: () => ({
        // 목록
        list: [],

        // 페이징
        page: 1,
        pageCount: 0,
        itemsPerPage: 12,

        // 검색 키워드
        keyword:""
    }),

    mounted() {
        // 검색
        this.submit()
    },

    methods: {
        // 검색
        submit(){
            this.$http.post('/api/admin/main/contact/list/my', {
                params: {
                    user_id: this.$store.state.user_id,
                    keyword: this.keyword
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 게시글로 이동
        read(item){
            this.$router.push("/contact/read?id="+item.id)
        },

        // 글쓰기
        write(){
            this.$router.push("/contact/write")
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 12px;
}

::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>