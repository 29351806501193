<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 상단 -->
            <ContactHeader />

            <div>
                <!-- 본문 -->
                <v-sheet class="py-6 px-2 rounded-15">
                    <!-- 제목 -->
                    <v-sheet class="px-4 d-flex justify-space-between">
                        <div>
                            <p style="font-size:18px;" class="mb-2">
                                <span style="color:#23D2E2;" class="mr-2">{{category}}</span>
                                <span style="color:#424361; font-weight:bold;">{{title}}</span>
                            </p>
                            <p style="font-size:16px;">
                                <span style="color:#B3B3C2;">{{new Date(created).toLocaleString()}}</span>
                            </p>
                        </div>

                        <!-- 프로필 -->
                        <div class="d-flex align-center mb-1 mr-1">
                            <Avatar v-if="writer" :user="writer" />
                            <!-- 닉네임 -->
                            <p class="my-0 mx-2 font-weight-bold" style="font-size:15px;">
                                {{nickname}}
                            </p>
                        </div>
                    </v-sheet>
                    <v-divider class="mx-4"></v-divider>

                    <!-- 본문 -->
                    <TipTapReader 
                        class="tiptap_style"
                        :options="reader_options"
                        v-if="reader_options.content"
                    />
                </v-sheet>


                <!-- 답변 -->
                <v-sheet v-if="isAnswered" class="py-6 px-2 mt-2 rounded-15">
                    <!-- 제목 -->
                    <v-sheet class="px-4">
                        <p style="font-size:16px;">
                            <span style="color:#F8468D;" class="mr-2">답변완료</span>
                            <span style="color:#B3B3C2;">{{new Date(answer.created).toLocaleString()}}</span>
                        </p>
                    </v-sheet>

                    <!-- 본문 -->
                    <TipTapReader
                        class="tiptap_style mt-2"
                        :options="answer_options"
                        v-if="answer_options.content"
                    />
                </v-sheet>

                <!-- 답변하기 -->
                <v-sheet v-if="$store.state.type=='관리자' && !isAnswered" class="py-6 px-2 mt-2 rounded-15">
                    <!-- 제목 -->
                    <v-sheet class="px-4">
                        <p style="font-size:16px;">
                            <span style="color:#F8468D;" class="mr-2">답변하기</span>
                        </p>
                    </v-sheet>
                    <TipTapWriter 
                        class="tiptap_style mx-3"
                        :options="writer_options"
                    />
                    <v-sheet class="mt-4">
                        <v-sheet class="d-flex justify-center pa-1">
                            <v-btn dark depressed width="140" height="40" class="mx-2 rounded-10" color="#DFDFDF" @click="$router.go(-1)">
                                취소하기
                            </v-btn>
                            <v-btn dark depressed width="140" height="40" class="mx-2 rounded-10" color="primary" @click="submit()">
                                답변하기
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>

                <v-btn dark depressed height="40" width="85" class="rounded-10 font-weight-bold mt-2" color="#ADAFCA" @click="$router.push('/contact/list')">
                    목록
                </v-btn>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 제목 -->
            <div
                class="d-flex justify-space-between align-center px-5 py-3 mobile"
            >
                <p
                    style="font-size:16px; font-weight:bold; cursor:pointer;"
                    class="mb-0"
                    @click="$router.push('/contact/list')"
                >
                    내 문의내역
                </p>

                <v-btn
                    width="94"
                    height="28"
                    style="font-size:12px;"
                    dark
                    color="#615DFA"
                    class="rounded-10"
                    depressed
                    @click="$router.push('/contact/write')"
                >
                    <v-icon small class="mr-1">mdi-pencil-outline</v-icon>
                    문의하기
                </v-btn>
            </div>

            <div>
                <!-- 본문 -->
                <v-sheet class="py-3 px-1 mx-4 rounded-15">
                    <!-- 제목 -->
                    <v-sheet class="px-4 d-flex justify-space-between">
                        <div>
                            <p style="font-size:12px;" class="mb-1">
                                <span style="color:#23D2E2;" class="mr-2">{{category}}</span>
                                <span style="color:#424361; font-weight:bold;">{{title}}</span>
                            </p>
                            <p style="font-size:10px;">
                                <span style="color:#B3B3C2;">{{new Date(created).toLocaleString()}}</span>
                            </p>
                        </div>
                    </v-sheet>
                    <v-divider class="mx-4"></v-divider>

                    <!-- 본문 -->
                    <TipTapReader 
                        class="tiptap_style"
                        :options="reader_options"
                        v-if="reader_options.content"
                    />
                </v-sheet>


                <!-- 답변 -->
                <v-sheet v-if="isAnswered" class="py-3 px-1 mx-4 mt-3 rounded-15">
                    <!-- 제목 -->
                    <v-sheet class="px-4">
                        <p style="font-size:12px;" class="mb-0">
                            <span style="color:#F8468D;" class="mr-2">답변완료</span>
                            <span style="color:#B3B3C2;">{{new Date(answer.created).toLocaleString()}}</span>
                        </p>
                    </v-sheet>

                    <!-- 본문 -->
                    <TipTapReader
                        class="tiptap_style mt-2"
                        :options="answer_options"
                        v-if="answer_options.content"
                    />
                </v-sheet>

                <!-- 답변하기 -->
                <v-sheet v-if="$store.state.type=='관리자' && !isAnswered" class="py-3 px-1 mx-4 mt-3 rounded-15">
                    <!-- 제목 -->
                    <v-sheet class="px-4">
                        <p style="font-size:12px;" class="mb-0">
                            <span style="color:#F8468D;" class="mr-2">답변하기</span>
                        </p>
                    </v-sheet>
                    <TipTapWriter 
                        class="tiptap_style mx-3"
                        :options="writer_options"
                    />
                    <v-sheet class="mt-4">
                        <v-sheet class="d-flex justify-center pa-1">
                            <v-btn dark depressed width="140" height="40" class="mx-2 rounded-10" color="#DFDFDF" @click="$router.go(-1)">
                                취소하기
                            </v-btn>
                            <v-btn dark depressed width="140" height="40" class="mx-2 rounded-10" color="primary" @click="submit()">
                                답변하기
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>

                <div
                    class="mt-2 mr-4 d-flex justify-end mb-16"
                >
                    <v-btn dark depressed height="34" width="80" class="rounded-10 font-weight-bold mt-2" color="#ADAFCA" @click="$router.push('/contact/list')">
                    목록
                </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ContactHeader from "./components/Header"
import Avatar from "@/components/Avatar"
import TipTapReader from "@/components/tiptap/Reader"
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    components: {
        ContactHeader,
        Avatar,
        TipTapReader,
        TipTapWriter
    },

    data: () => ({
        // 읽기
        reader_options: {
            content: '',
            editable: false,
            supportImage: true,
            supportVideo: true
        },

        // 답변
        answer_options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        // 쓰기
        writer_options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        writer: "",
        nickname: "",
        category: "",
        title: "",
        created: "",

        isAnswered: false,

        answer: {
            writer: "",
            created: ""
        }
    }),

    mounted() {
        // 게시글 불러오기
        this.$http.post('/api/admin/main/contact/read', {
            params: {
                id: this.$route.query.id
            }
        }).then(res => {
            this.writer = res.data[0].writer
            this.nickname = res.data[0].nickname
            this.category = res.data[0].category
            this.title = res.data[0].title
            this.reader_options.content = res.data[0].content
            this.created = res.data[0].created
        })

        this.load_answer()
    },

    methods: {
        // 답변 불러오기
        load_answer(){
            this.$http.post('/api/admin/main/contact/answer/list', {
                params: {
                    contact_id: this.$route.query.id
                }
            }).then((res) => {
                if(res.data.length){
                    this.answer_options.content = res.data[0].content
                    this.answer.writer= res.data[0].writer
                    this.answer.created= res.data[0].created
                    this.isAnswered = true
                }
            })
        },

        // 답변 작성하기
        submit(){
            if(this.writer_options.content==""){
                alert("답변 내용을 입력해주세요")
            }else{
                this.$http.post('/api/admin/main/contact/answer/insert', {
                    params: {
                        contact_id: this.$route.query.id,
                        writer: this.$store.state.user_id,
                        content: this.writer_options.content
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        // 문의자에게 알림
                        this.$http.post('/api/admin/main/contact/answer/insert/alarm', {
                            params: {
                                user_id: this.writer,
                                sender: this.$store.state.user_id,
                                type: "contact_answered",
                                link: this.$route.fullPath
                            }
                        })
                        alert("답변이 등록되었습니다.")
                        this.load_answer()
                    }
                })
            }
        }
    }
}
</script>
