<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 상단 -->
            <ContactHeader />

            <!-- 본문 -->
            <v-sheet class="py-4 px-6 rounded-15">
                <v-sheet class="pt-4 pb-2 px-1">
                    <v-sheet class="d-flex">
                        <v-sheet outlined class="pa-1 mr-2 rounded-10">
                            <v-sheet style="width:224px;">
                                <v-select
                                    hide-details
                                    dense
                                    solo
                                    flat
                                    placeholder="카테고리"
                                    :items="select_list.category"
                                    v-model="category"
                                    @change="category_changed()"
                                ></v-select>
                            </v-sheet>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 rounded-10" style="width:100%;">
                            <v-text-field
                                class="title_field"
                                hide-details
                                dense
                                placeholder="문의 제목을 입력해주세요"
                                solo
                                flat
                                v-model="title"
                            ></v-text-field>
                        </v-sheet>
                    </v-sheet>
                    <v-sheet class="mt-2">
                        <TipTapWriter 
                            class="tiptap_style"
                            :options="options"
                            @emit_count="import_content_count"
                            :key="componentKey"
                        />
                    </v-sheet>

                    <!-- 하단 버튼 -->
                    <v-sheet class="mt-3 d-flex justify-center">
                        <v-btn
                            height="49"
                            width="140"
                            class="mx-2 font-weight-bold rounded-10"
                            style="font-size:16px;"
                            color="primary"
                            dark
                            depressed
                            @click="submit()"
                        >
                            문의하기
                        </v-btn>
                        <v-btn
                            height="49"
                            width="140"
                            class="mx-2 font-weight-bold rounded-10"
                            style="font-size:16px;"
                            color="#ADAFCA"
                            dark
                            depressed
                            @click="$router.go(-1)"
                        >
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 제목 -->
            <div
                class="d-flex justify-space-between align-center px-5 py-3 mobile"
            >
                <p
                    style="font-size:16px; font-weight:bold; cursor:pointer;"
                    class="mb-0"
                    @click="$router.push('/contact/list')"
                >
                    내 문의내역
                </p>
            </div>

            <div class="mx-3">
                <!-- 카테고리 -->
                <v-sheet 
                    class="pa-1 mb-2 rounded-10 mobile"
                    outlined
                >
                    <v-select
                        style="height:28px;"
                        hide-details
                        dense
                        solo
                        flat
                        placeholder="카테고리"
                        :items="select_list.category"
                        v-model="category"
                        @change="category_changed()"
                    >
                        <template v-slot:append>
                            <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </v-sheet>

                <!-- 제목 -->
                <v-sheet
                    class="pa-1 rounded-10 mobile"
                    outlined
                >
                    <v-text-field
                        style="height:28px;"
                        class="title_field"
                        hide-details
                        dense
                        placeholder="제목을 입력해주세요"
                        solo
                        flat
                        v-model="title"
                    ></v-text-field>
                </v-sheet>

                <!-- 본문 -->
                <TipTapWriterMobile
                    class="tiptap_style mt-2"
                    :options="options"
                    @emit_count="import_content_count"
                />

                <!-- 저작권법 안내문구 -->
                <v-sheet
                    height="24"
                    color="#F8468D"
                    class="rounded-10 pt-1 mt-2"
                >
                    <p
                        class="white--text text-center mb-0"
                        style="font-size:10px; letter-spacing:-0.5px;"
                    >
                        타인이 촬영한 사진을 무단으로 사용시 저작권법 위반으로 처벌 될 수 있습니다.
                    </p>
                </v-sheet>

                <!-- 버튼 -->
                <div class="d-flex justify-center mb-6 mt-4">
                    <v-btn
                        height="33"
                        width="84"
                        class="mr-3 font-weight-bold rounded-10"
                        style="font-size:12px;"
                        color="primary"
                        dark
                        depressed
                        @click="submit()"
                    >
                        등록
                    </v-btn>
                    <v-btn
                        height="33"
                        width="84"
                        class="font-weight-bold rounded-10"
                        style="font-size:12px;"
                        color="#ADAFCA"
                        dark
                        depressed
                        @click="$router.go(-1)"
                    >
                        취소
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ContactHeader from "./components/Header"
import TipTapWriter from "@/components/tiptap/Writer"
import TipTapWriterMobile from "@/components/tiptap/WriterMobile"

export default {
    components: {
        ContactHeader,
        TipTapWriter,
        TipTapWriterMobile
    },

    data: () => ({
        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        componentKey: 0,

        category: "",
        title: "",

        select_list: {
            category: [
                "카드목록", 
                "카드거래", 
                "콜렉션북", 
                "덱레시피", 
                "카드랭킹", 
                "포인트몰", 
                "커뮤니티", 
                "마이페이지", 
                "유저신고",
                "공인샵/취급매장 등록",
                "아이디어/건의",
                "버그/제보",
                "회원탈퇴", 
                "사업제휴",
                "기타"
            ],
        }
    }),

    mounted(){
        if(!this.$store.state.is_logined){
            alert("로그인 이후에만 이용가능한 페이지입니다.")
            this.$router.push("/")
        }
    },

    methods: {
        forceRerender() {
            this.componentKey += 1
        },

        category_changed(){
            if(this.category=="공인샵/취급매장 등록"){
                this.options.content = 
                `
                    매장 이름 :<br/>
                    <br/>
                    담당자 :<br/>
                    <br/>
                    담당자 연락처 :<br/>
                    <br/>
                    매장 전화번호 :<br/>
                    <br/>
                    매장 주소지 :<br/>
                    <br/>
                    매장 운영시간 :<br/>
                    <br/>
                    이메일 :
                `
                this.forceRerender()
            }
        },

        cancel(){
            if(confirm("지금 작성하고 계신 글이 저장되지 않을 수 있습니다.\n페이지를 이동하시겠습니까?")){
                this.$router.go(-1)
            }
        },

        submit(){
            if(this.category == ""){
                alert("카테고리를 선택해주세요")
            }else if(this.title == ""){
                alert("문의 제목을 입력해주세요")
            }else if(this.options.content == ""){
                alert("문의 내용을 입력해주세요")
            }else{
                this.$http.post('/api/admin/main/contact/insert', {
                    params: {
                        writer: this.$store.state.user_id,
                        category: this.category,
                        title: this.title,
                        content: this.options.content
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("문의하기가 정상적으로 접수되었습니다.")
                        this.$router.push("/")
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
/* 모바일 카테고리 */
::v-deep .mobile .v-select {
    min-height: 28px;
}

/* 검색 keyword */
::v-deep .mobile .v-text-field {
    font-size:12px;
    min-height: 28px;
    height: 28px;
}

/* v-text-field 조절용 */
::v-deep .mobile .v-text-field .v-input__control {
    min-height: 28px;
    height: 28px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 28px;
    height:28px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 28px;
    min-height: 28px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-select .v-select__selections {
    min-height: 28px !important;
    height: 28px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}


::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px; /* 글꼴 크기 조절 */
    height: 28px;
}

::v-deep .mobile .v-select .v-input__append-inner,
::v-deep .mobile .v-select .v-input__prepend-inner {
    margin-top: 0;
}

</style>